import React from 'react';
import { Text, Card } from 'rebass';

export const ReadOnlyDisclaimer = () => (
  <Card p={4} mb={4} bg="intent.warning" sx={{ boxShadow: 0 }}>
    <Text color="black">
      This application provides direct access to IT Service Automation. You have
      read-only access.
    </Text>
  </Card>
);

export const AdminDisclaimer = () => (
  <>
    <Card p={4} mb={4} bg="intent.warning" sx={{ boxShadow: 0 }}>
      <Text color="black">
        This application provides direct access to IT Service Automation.{' '}
        <b>
          Actions taken here cannot be undone and have immediate financial and
          operational impact.
        </b>{' '}
        By using this tool, you agree that you are responsible for ensuring that
        provisioned services have funding in place.
      </Text>
    </Card>
    <Card p={4} mb={4} bg="intent.warning" sx={{ boxShadow: 0 }}>
      <Text color="black">
        Prices given within ITX are a reflection of internal LF costs, and
        corresponding project fees for those services will vary depending on
        each project’s IT Service Plan/Agreement.
      </Text>
    </Card>
  </>
);
