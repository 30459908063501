import { useRef, useState, useEffect, useCallback } from 'react';
import format from 'string-template';
import { toaster } from 'evergreen-ui';

import apiClient from '../utils/api-client';

enum ApiMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete'
}

const constructHook = <BodyType, ReturnType>(
  targetUrl: string,
  method: ApiMethod,
  toastErrors = true
): [
  (body?: Partial<BodyType>, targetArg?: object) => Promise<ReturnType>,
  boolean
] => {
  /* eslint-disable react-hooks/rules-of-hooks */
  const [loading, setLoading] = useState(false);
  const ref = useRef<AbortController | null>(); // Add `| null` to indicate a mutable value

  useEffect(
    () => () => {
      if (loading && ref.current) {
        ref.current.abort();
      }
    },
    [loading]
  );

  const fetchData = useCallback(
    async (target: string, body: Partial<BodyType> | string) => {
      let result;
      try {
        ref.current = new AbortController();
        // tslint:disable-next-line:no-non-null-assertion
        const api = apiClient[method];
        if (!api) {
          throw new Error(
            'No `ky` instance present, or the requested method is not present.'
          );
        }

        const response = await api(target, {
          signal: ref.current.signal,
          json: (body as unknown) as object
        });
        // NOTE: 204 doesn't have body.
        if (response.status !== 204) {
          result = await response.json();
        }
      } catch (error) {
        if (toastErrors && error.name !== 'AbortError') {
          toaster.danger(error.message);
        }
        throw error;
      } finally {
        ref.current = null;
      }

      return result;
    },
    [method, toastErrors]
  );

  const callApi = useCallback(
    async (body?: Partial<BodyType>, targetArg?: object) => {
      let target = targetUrl;
      let result;

      if (targetArg) {
        target = format(targetUrl, targetArg);
      }

      try {
        setLoading(true);
        result = await fetchData(target, body || '');
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }

      return (result as unknown) as ReturnType;
    },
    [fetchData, targetUrl]
  );

  return [callApi, loading];
};

export const useGet = <ReturnType>(url: string, toast?: boolean) =>
  constructHook<undefined, ReturnType>(url, ApiMethod.GET, toast);

export const usePut = <BodyType, ReturnType>(url: string, toast?: boolean) =>
  constructHook<BodyType, ReturnType>(url, ApiMethod.PUT, toast);

export const usePost = <BodyType, ReturnType>(url: string, toast?: boolean) =>
  constructHook<BodyType, ReturnType>(url, ApiMethod.POST, toast);

export const usePatch = <BodyType, ReturnType>(url: string, toast?: boolean) =>
  constructHook<BodyType, ReturnType>(url, ApiMethod.PATCH, toast);

export const useDelete = <BodyType, ReturnType>(url: string, toast?: boolean) =>
  constructHook<BodyType, ReturnType>(url, ApiMethod.DELETE, toast);
