import React, { useState, FormEvent } from 'react';
import { Box, Button, Flex } from 'rebass';
import { Redirect } from 'react-router-dom';
import { useFormState } from 'react-use-form-state';
import { Label, Input, Select } from '@rebass/forms';

import Spinner from '../UI/Spinner';
import { TooltipText } from '../UI/TooltipText';
import { FloatingCard } from '../UI/FloatingCard';
import { usePost } from '../../hooks/use-itx-api';
import { Project, ProjectFormationStatus } from '../../models/project';

interface AddProjectFormValues {
  shortName: string;
  prettyName: string;
  seriesName: string;
  formationStatus: ProjectFormationStatus;
}

const emptyProject: Project = {
  shortName: '',
  formationStatus: ProjectFormationStatus.Forming,
  canonicalDomain: '',
  prettyName: '',
  seriesName: ''
};

const tooltips = {
  prettyName:
    'Enter the full name of the project here. Note: This can be changed later if necessary.',
  shortName:
    'Enter an abbreviated name (such as an acronym) that can uniquely identify the project. Note: This must be unique to this project and cannot be changed later.',
  formationStatus:
    'Select Forming (if in a private, pre-launch phase) or Formed (the project has publicly launched with a PR release).',
  seriesName:
    '(Optional) If the project is a series of LF Projects, LLC, enter the series name for the project; otherwise, leave it blank. (Do NOT provide the entire entity name, just the part before "a Series of LF Projects, LLC".)',
  resetFields: 'Clear the new project fields and start over.',
  createProject: 'Submit and save the defined project.'
};

// tslint:disable-next-line:max-func-body-length
export const AddProject = () => {
  const [addProject, loading] = usePost<Project, void>('projects');
  const [redirectPath, setRedirectPath] = useState<string>();
  const [formState, { text, select, label }] = useFormState<
    AddProjectFormValues
  >(emptyProject, { withIds: true });

  const addProjectAndRedirect = async (e: FormEvent) => {
    e.preventDefault();
    const newProject = { ...emptyProject, ...formState.values };
    try {
      await addProject(newProject);
      setRedirectPath(`project/${newProject.shortName}/domains`);
    } catch (error) {
      if (error.name === 'AbortError') return;
      console.error(error);
    }
  };

  return (
    <FloatingCard title="Create a Project">
      <form onSubmit={addProjectAndRedirect}>
        <Label {...label('prettyName')}>
          <TooltipText tip={tooltips.prettyName}>Pretty Name</TooltipText>
        </Label>
        <Input {...text('prettyName')} placeholder="Example Project" required />
        <Flex my={3}>
          <Box width={1 / 3} pr={2}>
            <Label {...label('shortName')}>
              <TooltipText tip={tooltips.shortName}>Short Name</TooltipText>
            </Label>
            <Input {...text('shortName')} placeholder="exmpl" required />
          </Box>
          <Box width={1 / 3} px={2}>
            <Label {...label('formationStatus')}>
              <TooltipText tip={tooltips.formationStatus}>
                Formation Status
              </TooltipText>
            </Label>
            <Select {...select('formationStatus')} required>
              <option value={ProjectFormationStatus.Forming}>Forming</option>
              <option value={ProjectFormationStatus.Formed}>Formed</option>
            </Select>
          </Box>
          <Box width={1 / 3} pl={2}>
            <Label {...label('seriesName')}>
              <TooltipText tip={tooltips.seriesName}>Series Name</TooltipText>
            </Label>
            <Input {...text('seriesName')} placeholder="(If Applicable)" />
          </Box>
        </Flex>
        {loading ? (
          <Spinner inline />
        ) : (
          <Flex justifyContent="flex-end">
            {redirectPath && <Redirect to={redirectPath} />}
            <Button
              as="input"
              type="reset"
              variant="outline"
              mr={2}
              onClick={formState.reset}
              value="Reset Fields"
            />
            <Button
              as="input"
              type="submit"
              variant="confirm"
              value="Create Project"
            />
          </Flex>
        )}
      </form>
    </FloatingCard>
  );
};
